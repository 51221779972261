import React from 'react';
import { Button } from 'reactstrap';
import axios from "axios";

const DeleteContactModal = (props) => {

    const deleteContact = () => {
        return axios.delete(`/api/clients/contacts/${props.id}`)
            .then(() => {
                props.close();
                props.onContactDeleted();
            });
    }

    return (
        <div>
            <div className="title d-flex flex-row justify-content-between align-items-center mb-4">
                Удаление контактного лица
                <span className="close-icon" onClick={props.close}>
                </span>
            </div>
            <div className='title mb-4'>
                Вы уверены, что хотите удалить контактное лицо?
            </div>
            <div className='d-flex flex-row justify-content-between w-100'>
                <Button
                    color="danger"
                    outline
                    onClick={props.close}
                >
                    Отмена
                </Button>
                <Button
                    color="warning"
                    outline
                    onClick={() => deleteContact()}
                >
                    Удалить
                </Button>

            </div>
        </div>

    );
}

export default DeleteContactModal;