import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import Popup from "reactjs-popup";

import {dateToLocale, activationCountStyle, renderLicenseStatus} from '../../utils/common';

const LicenseHistoryModal = (props) => {
    const {id} = useParams();

    const [licenseList, setLicenseList] = useState(null);

    useEffect(() => {
        fetchHistory(id);
    }, [id]);

    const fetchHistory = (id) => {
        axios.get(`/api/licenses/history/${id}`)
            .then((response) => {
                setLicenseList(response.data);
            });
    }

    const renderRow = (l) => {
        return(
            <div className='table-row d-flex flex-row' key={l.id}>
                <div className='w-25'>{l.userName}</div>
                <div className='w-20'> 
                    <span className={activationCountStyle(l)}>{l.activationsLimit - l.activationsCount}</span>
                </div>
                <div className='w-25'>{dateToLocale(l.creationDate)}</div>
                <div className='w-20'>
                    {renderLicenseStatus(l.status)}
                </div>
                <div className='w-10 d-flex flex-end justify-content-end'>
                    <Popup
                        trigger={<a href={`/api/licenses/qr/${l.id}`} download={l.id}>
                            <i className='qr-code-icon'></i>
                        </a>}
                        position="bottom center"
                        on="hover"
                        className='hover'
                        >
                            Скачать
                    </Popup>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="title d-flex flex-row justify-content-between align-items-center mb-4">
                История QR-кодов               
                <span className="close-icon" onClick={props.close}></span>
            </div>
            <div className='table-header table-row d-flex flex-row'>
                <div className='w-25'>Кто оформил</div>
                <div className='w-20'>Кол-во активаций</div>
                <div className='w-25'>Дата оформления</div>
                <div className='w-20'>Статус</div>
                <div className='w-10'></div>
            </div>
            {
                licenseList &&
                licenseList.map(l => renderRow(l))
            }
        </div>

    );
}

export default LicenseHistoryModal;