import React, {Component} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import axios from "axios";

import './styles/custom.css'

import {Layout} from './components/Layout';
import {ClientsList} from "./pages/clients/ClientsList";
import {ClientInfo} from "./pages/clients/ClientInfo";
import {Login} from "./pages/login/Login";
import LicenseInfo from './pages/licenses/LicenseInfo';


axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        window.location.href = '/login';
    }
    return Promise.reject(error);
});

export default class App extends Component {
    static displayName = App.name;


    render() {
        return (
            <Switch>
                <Route exact path='/login' component={Login} />
                <Layout>
                    <Route exact path='/clients'>
                        <ClientsList archive={ false } />
                    </Route>
                    <Route exact path='/archive'>
                        <ClientsList archive={ true } />
                    </Route>
                    <Route path='/clients/:id' component={ClientInfo}/>
                    <Route path='/license/:id' component={LicenseInfo}/>
                    <Route exact path="/" render={() => <Redirect to="/clients"/>}/>
                </Layout>
            </Switch>
        );
    }
}
