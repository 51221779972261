import React from 'react';
import { Button } from 'reactstrap';

const ConfirmModal = (props) => {
    return (
        <div>
            <div className="title d-flex flex-row justify-content-between align-items-center mb-4">
                {props.title}
                <span className="close-icon" onClick={props.close}>
                </span>
            </div>
            <div className='title mb-4'>
                {props.dectiption}
            </div>
            <div className='d-flex flex-row justify-content-between w-100'>
                <Button
                    color="danger"
                    outline
                    onClick={props.close}
                >
                    Отмена
                </Button>
                <Button
                    color="warning"
                    outline
                    onClick={props.onConfirmed}
                >
                    {props.btnText}
                </Button>
           
            </div>
        </div>

    );
}

export default ConfirmModal;