import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import { Modal, ModalBody } from 'reactstrap';
import axios from "axios";
import Popup from "reactjs-popup";

import { dateToLocale, ActivationStatus } from '../../utils/common';
import ConfirmModal from '../../shared/ConfirmModal';



const ActivationList = (props) => {
    const {id} = useParams();

    const [activations, setActivations] = useState([]);

    const [blockLicense, setBlockLicense] = useState(null);
    const toggleBlockModal = (model) => setBlockLicense(model);

    const [restoreLicense, setRestoreLicense] = useState(null);
    const toggleRestoreModal = (model) => setRestoreLicense(model);

    useEffect(() => {
        fetchActivations(id);
    }, [id, props.licenseStatus]);

    const fetchActivations = (id) => {
        return axios.get(`/api/activations/${id}`)
            .then((response) => {
                setActivations(response.data);
            });
    }

    const refresh = () => {
        props.refresh()
        fetchActivations(id);
    }

    const block = (act) => {
        return axios.post(`/api/activations/block`, blockLicense)
            .then((response) => {
                toggleBlockModal();
                refresh();
            });
    }

    const restore = (act) => {
        return axios.post(`/api/activations/restore`, restoreLicense)
            .then((response) => {
                toggleRestoreModal();
                refresh();
            });
    }

    const renderStatus = (status) => {
        switch(status){
            case ActivationStatus.active:
                return <div className='status success'>Активна</div>;
            case ActivationStatus.blockedByAdmin:
                return <div className='status danger'>Отозвана</div>;
            case ActivationStatus.droppedByClient:
                return <div className='status warning'>Возврат</div>;
            default:
                return null;
        }
    };
   
    const renderRow = (act) => {
        return(
            <div className='table-row d-flex flex-row align-items-center' key={act.id}>
                <div className='w-30'>{act.deviceId}</div>
                <div className='w-20'>{dateToLocale(act.activationTime)}</div>
                <div className='w-20'>{act.status === ActivationStatus.active ? null : dateToLocale(act.lastDroppedDate)}</div>
                <div className='w-20'>{renderStatus(act.status)}</div>
                <div className='w-10 d-flex justify-content-end'>
                    <Popup
                        trigger={<span className='triple-dot-menu-icon'></span>}
                        position="right center"
                        closeOnDocumentClick
                        className='click'
                    >
                        {
                            act.status !== ActivationStatus.blockedByAdmin
                                ? <div className='pointer' onClick={() => toggleBlockModal(act)}>Отозвать </div>
                                : <div className='pointer' onClick={() => toggleRestoreModal(act)}>Восстановить </div>
                        }
                    </Popup>
                </div>
            </div>
        );
    }

    return (
        <div>
            <h4>Активации</h4>
            <div>
                <div className='table-header table-row d-flex flex-row'>
                    <div className='w-30'>Номер устройства</div>
                    <div className='w-20'>Дата активации</div>
                    <div className='w-20'>Дата отзыва</div>
                    <div className='w-20'>Статус</div>
                    <div className='w-10'></div>
                </div>
                {
                    activations.map(l => renderRow(l))
                }
            </div>
            <Modal isOpen={!!blockLicense} toggle={toggleBlockModal} size='sm'>
                <ModalBody>
                    <ConfirmModal
                        title="Блокировать активацию"
                        dectiption="Вы уверены, что хотите блокировать активацию?"
                        btnText="Заблокировать"
                        onConfirmed={block}
                        close={toggleBlockModal} 
                    />
                </ModalBody>
            </Modal>
            <Modal isOpen={!!restoreLicense} toggle={toggleRestoreModal} size='sm'>
                <ModalBody>
                    <ConfirmModal
                        title="Восстановить активацию"
                        dectiption="Активация вернется в статус, который был до блокирования?"
                        btnText="Восстановить"
                        onConfirmed={restore}
                        close={toggleRestoreModal} 
                    />
                </ModalBody>
            </Modal>
        </div>

    );
}

export default ActivationList;