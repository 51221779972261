import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useParams, useHistory} from "react-router-dom";
import { Modal, ModalBody, Button } from 'reactstrap';

import ActivationList from './ActivationList';
import BlockLicenseModal from './BlockLicenseModal';
import LicenseAddEditModal from './LicenseAddEditModal';
import RegenerateLicenseModal from './RegenerateLicenseModal';
import LicenseHistoryModal from './LicenseHistoryModal';

import {dateToLocale, activationCountStyle, LicenseStatus, renderLicenseStatus} from '../../utils/common';
import LicenseTransactionsModal from './LicenseTransactionsModal';


const LicenseInfo = () => {
    const {id} = useParams();
    const [license, setLicense] = useState(null);
    const [licenseTransactions, setLicenseTransactions] = useState([]);

    const [blockLicenseModal, setBlockLicenseModal] = useState(false);
    const toggleBlockLicenseModal = () => setBlockLicenseModal(!blockLicenseModal);

    const [editLicenseModal, setEditLicenseModal] = useState(false);
    const toggleEditLicenseModal = () => setEditLicenseModal(!editLicenseModal);

    const [regenerateModal, setRegenerateModal] = useState(false);
    const toggleRegenerateModal = () => setRegenerateModal(!regenerateModal);

    const [historyModal, setHistoryModal] = useState(false);
    const toggleHistoryModal = () => setHistoryModal(!historyModal);

    const [isLicenseTransactionsModalOpen, setIsLicenseTransactionsModalOpen] = useState(false);
    const toggleLicenseTransactionsModal = () => setIsLicenseTransactionsModalOpen(!isLicenseTransactionsModalOpen);

    const history = useHistory();

    useEffect(() => {
        setBlockLicenseModal(false); // prevent open modal ofter regeneration.
        fetchLicense(id);
        fetchLicenseTransactions(id);
    }, [id]);

    const getBack = () => {
        history.push(`/clients/${license.clientId}`);
    }

    const fetchLicense = (id) => {
        return axios.get(`/api/licenses/view/${id}`)
            .then((response) => {
                setLicense(response.data);
            });
    }

    const fetchLicenseTransactions = (id) => {
        return axios.get(`/api/licenses/transactions/${id}`)
            .then((response) => {
                setLicenseTransactions(response.data);
            });
    }

    const licenseStatusChanged = (status) => {
        const blocked = {...license, status};
        setLicense(blocked);
    }

    const licenseLimitChanged = (model) => {
        const changed = {...license, activationsLimit: model.activationsLimit};
        setLicense(changed);
        fetchLicenseTransactions(id);
    }

    if (!license) return null;

    const renderActions = () => {
        if(license.status === LicenseStatus.active)
            return (
                <div className='mb-4'>
                    <img
                        alt='QR code'
                        src={`/api/licenses/qr/${license.id}`} 
                        style={{  width: '240px', height: '240px'}}/>
                    <div className='d-flex flex-row'>
                        <a download={`${license.id}.jpg`} href={`/api/licenses/qr/${license.id}`} >
                            <button className='btn btn-primary'>
                                Скачать
                            </button>
                        </a>
                        <Button
                            color="warning"
                            className='ml-4 no-border'
                            outline
                            onClick={() => toggleBlockLicenseModal()}
                        >
                            Отозвать
                        </Button>
                        <Modal isOpen={blockLicenseModal} toggle={toggleBlockLicenseModal} size='sm'>
                            <ModalBody>
                                <BlockLicenseModal license={license}
                                    onResolved={() => licenseStatusChanged(LicenseStatus.blocked)}
                                    close={toggleBlockLicenseModal}
                                />
                            </ModalBody>
                        </Modal>
                    </div>
                </div>
            );

        if(license.status === LicenseStatus.blocked)
            return (
                <div className='d-flex flex-row mb-4'>
                    <button className='btn btn-primary mr-3' onClick={toggleRegenerateModal}>
                        Перегенерировать QR-код
                    </button>
                    <button className='btn btn-primary' onClick={toggleHistoryModal}>
                        История QR-кодов
                    </button>
                    <Modal isOpen={historyModal} size='lg'>
                        <ModalBody>
                            <LicenseHistoryModal close={toggleHistoryModal}/>
                        </ModalBody>
                    </Modal>
                </div>
            );

        return null;
    }

    return (
        <div >
            <div className='pointer grey-1 d-flex flex-row align-items-center mb-2' onClick={() => getBack()}>
                <span className='nav-back-arrow'></span>
                Назад
            </div>

            <div className='d-flex flex-row align-items-center mb-4'>
                <h2 className='m-0 mr-4'>{license.client.name}</h2>
                {
                    license.client.isArchived
                        ? <div className='status danger mr-4'>В архиве</div>
                        : <div className='status success mr-4'>Активный</div>
                }
            </div>
            <div className='font-lg mb-2'>
                <span className='mr-2'>ИНН </span>
                <span className='grey-1'>{license.client.inn || '—'}</span>

                <span className='ml-3 mr-2'>КПП </span>
                <span className='grey-1'>{license.client.kpp || '—'}</span>

                <div className='d-flex flex-row mt-2'>
                    <span className='mr-2'>Лицензия</span>
                    <span className='activations-count'>{license.id}</span>
                </div>
                <div className='d-flex flex-row d-flex flex-row align-items-center mt-2'>
                    <span className='mr-2'>Статус </span>
                    {renderLicenseStatus(license.status)}
                </div>
                <div className='d-flex flex-row mt-2'>
                    <span className='mr-2'>Осталось активаций</span>
                    <span className={activationCountStyle(license)}>
                        {license.activationsLimit - license.activationsCount}
                    </span>
                    <span className='mx-2'>Всего активаций</span>
                    <span className='activations-count font-md'>
                        {license.activationsLimit || 0}
                    </span>
                    {
                        license.status === LicenseStatus.active
                        &&
                        <div>
                            <button className='btn btn-primary ml-3' onClick={() => toggleEditLicenseModal()}>
                                Изменить
                            </button>
                            <Modal isOpen={editLicenseModal} toggle={toggleEditLicenseModal} size='sm'>
                                <ModalBody>
                                    <LicenseAddEditModal license={license} 
                                        close={toggleEditLicenseModal} 
                                        refresh={licenseLimitChanged} 
                                        clientId={license.client.id} 
                                    />
                                </ModalBody>
                            </Modal>
                        </div>
                    }
                    <div>
                        <button className='btn btn-success ml-3' onClick={() => toggleLicenseTransactionsModal()}>
                            История изменений
                        </button>
                        <LicenseTransactionsModal
                            isOpen={isLicenseTransactionsModalOpen}
                            toggle={toggleLicenseTransactionsModal}
                            close={() => setIsLicenseTransactionsModalOpen(false)}
                            transactions={licenseTransactions}
                        />
                    </div>
                </div>
                <div className='d-flex flex-row mt-2'>
                    <span className='mr-2'>Активна с</span>
                    <span className='activations-count'>{dateToLocale(license.creationDate)}</span>
                </div>
            </div>
            {
                renderActions()
            }
            <ActivationList refresh={() => fetchLicense(id)} licenseStatus={license.status}/>
            <Modal isOpen={regenerateModal} toggle={toggleRegenerateModal} size='sm'>
                <ModalBody>
                    <RegenerateLicenseModal
                        license={license}
                        close={toggleRegenerateModal}
                        onResolved={() => licenseStatusChanged(LicenseStatus.regenerated)}
                    />
                </ModalBody>
            </Modal>
        </div>
    );
}

export default LicenseInfo;