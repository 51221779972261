import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { SortDirection, dateToLocale, sortData } from '../../utils/common';
import LicenseTransactionInfoModal from './LicenseTransactionInfoModal';

const LicenseTransactionsModal = ({isOpen, toggle, close, transactions}) => {
    const [sortedTransactions, setSortedTransactions] = useState([]);
    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState(SortDirection.ascending);

    const [isLicenseTransactionInfoModalOpen, setIsLicenseTransactionInfoModalOpen] = useState(false);
    const toggleLicenseTransactionInfoModal = () => setIsLicenseTransactionInfoModalOpen(!isLicenseTransactionInfoModalOpen);
    const [licenseTransactionInfo, setLicenseTransactionInfo] = useState(null);

    useEffect(() => {
        setSortedTransactions(transactions);
    }, [transactions]);

    const handleSort = (field) => {
        const direction = (field === sortField && sortDirection === SortDirection.ascending)
            ? SortDirection.descending
            : SortDirection.ascending
        setSortDirection(direction);
        setSortField(field);
        setSortedTransactions(sortData(sortedTransactions, field, direction));
    }

    const getSortClass = (field) => {
        return field === sortField ? sortDirection : 'no-sort';
    }

    const handleLicenseTransactionClick = (transaction) => {
        toggleLicenseTransactionInfoModal();
        setLicenseTransactionInfo(transaction);
    }

    const renderTransactions = () => {
        return (
            <>
                <div className='table-header table-row d-flex flex-row'>
                    <div className='w-10'>Кол-во</div>
                    <div className={'w-20 ' + getSortClass('author')} onClick={() => handleSort('author')}>Автор изменения</div>
                    <div className={'w-20 ' + getSortClass('operationDate')} onClick={() => handleSort('operationDate')}>Дата изменения</div>
                    <div className='w-50'>Комментарий</div>
                </div>
                <div className='overflow-auto' style={{maxHeight: '50vh'}}>
                    {sortedTransactions.map((transaction, index) => renderRow(transaction, index))}
                </div>
            </>
        );
    }

    const renderRow = (transaction, index) => {
        return (
            <div className='table-row d-flex flex-row align-items-center pointer pe-auto' key={index} onClick={() => handleLicenseTransactionClick(transaction)}>
                <div className={'w-10 ' + (transaction.activationsCount < 0 ? 'text-danger' : 'text-success')}>{(transaction.activationsCount > 0 ? '+' : '') + transaction.activationsCount}</div>
                <div className='w-20 text-truncate'>{transaction.author}</div>
                <div className='w-20'>{dateToLocale(transaction.operationDate)}</div>
                <div className='w-50 text-truncate'>{transaction.comment}</div>
            </div>
        );
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} size='lg'>
            <ModalBody>
                <div className="title d-flex flex-row justify-content-between align-items-center mb-4">
                    <span>История изменения количества активаций</span>
                    <span className="close-icon" onClick={close}></span>
                </div>
                {sortedTransactions.length ? renderTransactions() : "Для лицензии отсутствуют начисления активаций"}
                <LicenseTransactionInfoModal
                    isOpen={isLicenseTransactionInfoModalOpen}
                    toggle={toggleLicenseTransactionInfoModal}
                    close={() => setIsLicenseTransactionInfoModalOpen(false)}
                    transaction={licenseTransactionInfo}
                />
            </ModalBody>
        </Modal>
    );
}

export default LicenseTransactionsModal;
