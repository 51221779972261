import React, {useEffect, useState} from 'react';
import axios from "axios";
import {useParams} from "react-router-dom";
import { Modal, ModalBody } from 'reactstrap';
import Popup from "reactjs-popup";

import AddEditContactModal from './AddEditContactModal';
import DeleteContactModal from './DeleteContactModal';

const ContactList = (props) => {
    const { id: clientId } = useParams();
    const [contacts, setContacts] = useState([]);

    const [addEditModal, setAddEditModal] = useState(false);
    const [addEditModel, setAddEditModel] = useState(null);

    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const toggleAddEditModal = (model) => {
        setAddEditModel(model);
        setAddEditModal(!addEditModal);
    }

    const toggleDeleteModal = (id) => {
        setDeleteId(id);
        setDeleteModal(!deleteModal);
    }

    useEffect(() => {
        fetchContacts(clientId);
    }, [clientId]);

    const fetchContacts = (clientId) => {
        return axios.get(`/api/clients/contacts/${clientId}`)
            .then((response) => {
                setContacts(response.data);
            });
    }

    const onContactSavedDeleted = () => {
        fetchContacts(clientId);
    }

    const renderRow = (contact) => {
        return(
            <div className='table-row d-flex flex-row' key={contact.id}>
                <div className='w-30'>{contact.fullName}</div>
                <div className='w-20'>{contact.jobTitle}</div>
                <div className='d-flex flex-row align-items-center w-20'>
                    <span className='phone-icon mr-2'></span>
                    {contact.phone}
                </div>
                <div className='w-20'>{contact.email}</div>
               
                <div className='w-10 d-flex flex-row justify-content-end'>
                    <Popup
                        trigger={<span className='triple-dot-menu-icon ml-4'></span>}
                        position="right center"
                        closeOnDocumentClick
                        className='click'
                    >
                        <div className='pointer mb-2' onClick={() => toggleAddEditModal(contact)}>Редактировать</div>
                        <div className='pointer' onClick={() => toggleDeleteModal(contact.id)}>Удалить</div>
                    </Popup>
                </div>
            </div>
        );
    }


    return (
        <div>
            <div className='d-flex flex-row my-4'>
                <h3 className='mr-4'>Контактные лица</h3>
                <button className='btn btn-primary' onClick={() => toggleAddEditModal()}>
                    <span className='plus-icon-white mr-2'></span>
                    Добавить
                </button>
                <Modal isOpen={addEditModal} toggle={toggleAddEditModal} size='sm'>
                    <ModalBody>
                        <AddEditContactModal contact={addEditModel} close={toggleAddEditModal} onContactSaved={onContactSavedDeleted} />
                    </ModalBody>
                </Modal>
                <Modal isOpen={deleteModal} toggle={toggleDeleteModal} size='sm'>
                    <ModalBody>
                        <DeleteContactModal id={deleteId} close={toggleDeleteModal} onContactDeleted={onContactSavedDeleted} />
                    </ModalBody>
                </Modal>
            </div>
            <div className='table-header table-row d-flex flex-row'>
                <div className='w-30'>имя</div>
                <div className='w-20'>должность</div>
                <div className='w-20'>телефон</div>
                <div className='w-20'>еmail</div>
                <div className='w-10'></div>
            </div>
            {
                contacts.map(c => renderRow(c))
            }
        </div>
    );
}

export default ContactList;