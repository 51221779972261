import React from 'react';

//eslint-disable-next-line
const emailMask = /^[a-zA-Z0-9]+([\.'_-]?[a-zA-Z0-9]+)*[\+]?[a-zA-Z0-9]+([\.'_-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([\._]?[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]{2,3})+$/;

const dateToLocale = (dateStr) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }

    return new Date(dateStr).toLocaleString("ru", options).slice(0, -3);
};

const dateTimeToLocale = (dateStr) => {
    return dateToLocale(dateStr) + ' ' +  new Date(dateStr).toLocaleTimeString('ru-RU');
}

const activationCountStyle = (object) => {
    if(!object.activationsLimit) return 'activations-count font-md';

    let percent = object.activationsCount / object.activationsLimit;

    if(percent >= 0.8) return 'activations-count red font-md'

    if(percent <= 0.2) return 'activations-count green font-md'

    return 'activations-count font-md'; 
};

class LicenseStatus {
    static active = 0
    static blocked = 1
    static regenerated = 2
}


const renderLicenseStatus = (status) => {
    switch(status){
        case LicenseStatus.active: 
            return <div className='status success'>Активна</div>;
        case LicenseStatus.blocked: 
            return <div className='status danger'>Заблокирована</div>;
        case LicenseStatus.regenerated: 
            return <div className='status warning'>Перевыпущена</div>;
        default:
            return null;
    }
};

class ActivationStatus {
    static active = 0
    static blockedByAdmin = 1
    static droppedByClient = 2
}

const filterData = (data, searchData, filterField) => {
    let filteredData = [].concat(data ?? []);

    if (searchData !== null && searchData.trim() !== "") {
        let searchDataCleared = searchData.trim().toLowerCase();

        if (filterField === null) {
            return filteredData.filter((item) => {
                return Object.keys(item).some((key) => {
                    return typeof(item[key]) == 'string' ? item[key].toLowerCase().includes(searchDataCleared) : false;
                });
            })
        }

        return filteredData.filter((item) => {
            return typeof (item[filterField]) == 'string' ? item[filterField].toLowerCase().includes(searchDataCleared) : false;
        });
    }

    return filteredData;
}

class SortDirection {
    static ascending = 'ascending';
    static descending = 'descending';
}

const sortData = (data, sortField, sortDirection) => {
    let sortedData = [].concat(data ?? []);

    if (sortField !== null) {
        sortedData.sort((a, b) => {
            if (a[sortField] === null && b[sortField] === null) return 0;
            if (a[sortField] === null) return 1;
            if (b[sortField] === null) return -1;

            if (typeof (a[sortField]) == 'number' && typeof (b[sortField]) == 'number') {
                return (a[sortField] > b[sortField] ? 1 : -1) * (sortDirection === SortDirection.ascending ? 1 : -1);
            }

            return (a[sortField].toString().localeCompare(b[sortField].toString())) * (sortDirection === SortDirection.ascending ? 1 : -1);
        });
    }

    return sortedData;
}

export { dateToLocale, dateTimeToLocale, activationCountStyle, renderLicenseStatus, LicenseStatus, ActivationStatus, emailMask, filterData, SortDirection, sortData };