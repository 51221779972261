import React, {useState} from 'react';
import {Form, FormGroup, Label, Input, FormFeedback} from 'reactstrap';
import axios from 'axios';
import {useParams} from 'react-router-dom';

import {emailMask} from '../../utils/common'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ru from 'react-phone-input-2/lang/ru.json'
import { isValidPhoneNumber } from 'libphonenumber-js'

const AddEditContactModal = (props) => {
    const {id} = useParams();

    const [contact, setContact] = useState({
        fullName: props.contact?.fullName || '',
        email: props.contact?.email || '',
        phone: props.contact?.phone || '',
        jobTitle: props.contact?.jobTitle || '',
        id: props.contact?.id,
        clientId: id
    });

    const [phoneMeta, setPhoneMeta] = useState({});

    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        const model = {...contact, [event.target.name]: event.target.value};
        setContact(model);
        isFormValid(model);
    };

    const handlePhoneChange = (value, data, event, formattedValue) => {
        const model = {...contact, phone: (value === data.dialCode ? '' : formattedValue)};
        setContact(model);
        setPhoneMeta({...phoneMeta, value, data});
        isFormValid(model, {value, data});
    }

    const isFormValid = (model, phoneMeta) => {
        let err = {};
        if (!model.fullName || !model.fullName.trim()) {
            err.fullName = true
        }

        if (!model.email || !emailMask.test(model.email)) {
            err.email = true;
        }
        
        if (model.phone && phoneMeta?.value && !isValidPhoneNumber('+' + phoneMeta.value)) {
            err.phone = true;
        }

        setErrors(err);

        return Object.keys(err).length === 0;
    }

    const postContact = (event) => {
        event.preventDefault();

        if (!isFormValid(contact, phoneMeta)){
            return;
        }

        return axios.post("/api/clients/postcontact", contact)
            .then((response) => {
                props.close();

                props.onContactSaved(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    setErrors({...errors, '': error.response.data.message})
                }
            });
    }

    return (
        <div>
            <div className="title d-flex flex-row justify-content-between align-items-center mb-4">
                {props.contact ? 'Редактирование контакта' : 'Добавление контакта'}
                <span className="close-icon" onClick={props.close}></span>
            </div>
            <Form onSubmit={postContact}>
                {errors[''] && <div className='invalid-feedback d-block'>{errors['']}</div>}
                <FormGroup className='mb-4'>
                    <Label for="fullName">ФИО</Label>
                    <Input
                        name="fullName"
                        id="fullName"
                        maxLength="50"
                        invalid={errors.fullName}
                        placeholder="ФИО"
                        value={contact.fullName}
                        onChange={handleChange}
                    />
                    <FormFeedback>
                        Данное поле обязательно для заполнения
                    </FormFeedback>
                </FormGroup>
                <FormGroup className='mb-4'>
                    <Label for="jobTitle">Должность</Label>
                    <Input
                        name="jobTitle"
                        id="jobTitle"
                        maxLength="50"
                        placeholder="Должность"
                        value={contact.jobTitle}
                        onChange={handleChange}
                    />
                </FormGroup>
               
                <FormGroup className='mb-4'>
                    <Label for="email">Email</Label>
                    <Input
                        name="email"
                        id="email"
                        maxLength="50"
                        placeholder="Email"
                        value={contact.email}
                        invalid={errors.email}
                        onChange={handleChange}
                    />
                    <FormFeedback>
                        {contact.email ? "Неверный формат" : "Данное поле обязательно для заполнения"}
                    </FormFeedback>
                </FormGroup>
                <FormGroup className='mb-4'>
                    <Label for="phone">Телефон</Label>
                    <PhoneInput
                        country={'ru'}
                        localization={ru}
                        id="phone"
                        value={contact.phone || phoneMeta?.data?.dialCode}
                        onChange={handlePhoneChange}
                        placeholder='+7 (999) 123-4567'
                        inputClass={errors.phone && 'is-invalid'}
                        buttonClass={errors.phone && 'is-invalid'}
                    />
                    {errors.phone && <div className='invalid-feedback d-block'>Неверный формат</div>}
                </FormGroup>
                

                <button type="submit" className='btn btn-blue w-100 mt-3'>Сохранить</button>
            </Form>
        </div>

    );
}

export default AddEditContactModal;