import React, {useState} from 'react';

import {Form, FormGroup, Label, Input, FormFeedback, FormText} from 'reactstrap';
import axios from "axios";

const LicenseAddEditModal = (props) => {

    const [activationsLimit, setActivations] = useState('');
    const [comment, setComment] = useState('');
    const [errorDesc, setErrorDesc] = useState();


    const handleChange = (event) => {
        let value = event.target.value;

        isActivationsValid(value);
        setActivations(value);
    };

    const isActivationsValid = (value) => {
        let reason;
        let valueAsNumber = +value;

        if(!value) {
            reason = 'Данное поле обязательно для заполнения';
        }
        else if(!/^-?\d+$/.test(value) || value.startsWith('0') ) {
            reason = 'Введите целое число';
        }
        else if(valueAsNumber === 0 || (!props.license && valueAsNumber <= 0)) {
            reason = 'Введите число больше 0';
        }
        else if(props.license && (props.license.activationsLimit + valueAsNumber) <= 0){
            reason = `Введите число больше -${props.license.activationsLimit}`;
        }
        
        setErrorDesc(reason);
        return !reason;
    } 

    const handleComment = (event) => {
        let sub = event.target.value.slice(0, 200);
        setComment(sub);
    };

    const submit = (event) => {
        event.preventDefault();

        if(errorDesc || !isActivationsValid(activationsLimit)) {
            return;
        }

        const model =  {
            clientId: props.clientId,
            LicenseId: props.license?.id,
            activationsLimit,
            comment
        };

        return axios.post("/api/licenses/post", model)
            .then((response) => {
                props.refresh(response.data);
                props.close();
            });
    }

    return (
        <div>
            <div className="title d-flex flex-row justify-content-between align-items-center mb-4">
                {
                    props.license
                    ? 'Количество активаций'
                    : 'Новая лицензия'
                }
               
                <span className="close-icon" onClick={props.close}>
                </span>
            </div>
            <Form onSubmit={submit}>
                <FormGroup>
                    <Label for="activationsLimit">Количество активаций</Label>
                    <Input
                        name="activationsLimit"
                        id="activationsLimit"
                        placeholder="Количество активаций"
                        maxLength="7"
                        invalid={!!errorDesc}
                        value={activationsLimit}
                        onChange={handleChange}
                    />
                    <FormFeedback>
                        {errorDesc}
                    </FormFeedback>
                    {
                        (!errorDesc && !!props.license) &&
                        <FormText>
                            Для уменьшения количества активаций введите число со знаком "-"
                        </FormText>
                    }
                    
                </FormGroup>
                {
                    props.license
                    &&
                    <FormGroup>
                        <Label for="comment">Комментарий</Label>
                        <Input
                            name="comment"
                            id="comment"
                            value={comment}
                            onChange={handleComment}
                            maxLength="200"
                        />
                    </FormGroup>
                }

                <button type="submit" className='btn btn-blue' disabled={!!errorDesc}>Сохранить</button>
            </Form>
        </div>

    );
}

export default LicenseAddEditModal;