import axios from 'axios';
import React, { useEffect, useState } from 'react';

const Footer = () => {
    const year = new Date().getFullYear();

    const [gitCommitHash, setGitCommitHash] = useState(null);

    useEffect(() => {
        fetchGitCommitHash();
    }, []);

    const fetchGitCommitHash = () => {
        return axios.get('/api/settings/gitCommitHash')
            .then((response) => {
                setGitCommitHash(response.data);
            });
    }

    return (
        <>
            <div className='footer d-flex flex-row align-items-center justify-content-center'>
                <span className='mr-5'>{year} © Компания «Актив»</span>
                <span className={gitCommitHash && 'mr-5'}>+ 7 (495) 925-77-90</span>
                {gitCommitHash && <span style={{opacity: "0.7"}}>{gitCommitHash.substring(0, 7)}</span>}
            </div>
        </>
    )
}

export default Footer;
