import React from 'react';
import { Button } from 'reactstrap';
import axios from "axios";

const BlockLicenseModal = (props) => {

    const blockLicense = () => {
        return axios.post(`/api/licenses/block`, props.license)
            .then((response) => {
                props.onResolved();
            });
    }
  
    return (
        <div>
            <div className="title d-flex flex-row justify-content-between align-items-center mb-4">
                Отзыв лицензии
                <span className="close-icon" onClick={props.close}>
                </span>
            </div>
            <div className='title mb-4'>
                Вы уверены, что хотите отозвать QR-код? Это действие нельзя отменить.
            </div>
            <div className='d-flex flex-row justify-content-between w-100'>
                <Button
                    color="danger"
                    outline
                    onClick={props.close}
                >
                    Отмена
                </Button>
                <Button
                    color="warning"
                    outline
                    onClick={() => blockLicense()}
                >
                    Отозвать
                </Button>
           
            </div>
        </div>

    );
}

export default BlockLicenseModal;