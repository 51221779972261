import React, {useEffect, useState, useRef, useMemo} from 'react';
import axios from 'axios';
import {useHistory} from "react-router-dom";
import Popup from "reactjs-popup";
import { Modal, ModalBody, UncontrolledTooltip } from 'reactstrap';

import { activationCountStyle, filterData, SortDirection, sortData } from '../../utils/common';
import ClientAddEditModal from './ClientAddEditModal';
import ArchiveClientModal from './ArchiveClientModal';

export const ClientsList = (props) => {
    const [clients, setClients] = useState(null);

    const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);

    const [isArchiveClientModalOpen, setIsArchiveClientModalOpen] = useState(false);
    const [clientToArchive, setClientToArchive] = useState(null);

    const history = useHistory();

    const [searchData, setSearchData] = useState(null);

    const [filterField, setFilterField] = useState({ value: null, label: 'Все'});
    const filterPopupRef = useRef();
    const [filterPopupIsOpen, setFilterPopupIsOpen] = useState(false);

    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState(SortDirection.ascending);

    useEffect(() => {
        fetchClients(props.archive);
    }, []);

    const fetchClients = (archived) => {
        return axios.post('/api/clients', { archived })
            .then((response) => {
                setClients(response.data);
            });
    }

    const info = (clientId) => {
        history.push(`/clients/${clientId}`);
    }

    const onClientSaved = (newClient) => {
        var newList = clients.concat(newClient);
        setClients(newList);
    }

    const changeArchiveFilter = (archived) => {
        if (archived)
            history.push('/archive');
        else
            history.push('/clients');
    }

    const handleArchiveClientClick = (client) => {
        if (!client.isArchived && client.activationsLimit > 0) {
            setIsArchiveClientModalOpen(true);
            setClientToArchive(client);
        } else {
            changeClientArchiveStatus(client);
        }
    }

    const changeClientArchiveStatus = (client) => {
        client.isArchived = !props.archive;
        return axios.post('/api/clients/archive', client)
            .then(() => {
                fetchClients(props.archive);
            })
            .finally(() => setIsArchiveClientModalOpen(false));
    }

    const sortedAndFilteredClients = useMemo(() => {
        const filteredClients = filterData(clients, searchData, filterField.value);
        const sortedClients = sortData(filteredClients, sortField, sortDirection);
        return sortedClients;
    }, [clients, searchData, filterField, sortField, sortDirection]);

    const handleFilter = (value, label) => {
        setFilterField({ value, label });
        filterPopupRef.current.close();
    }

    const handleSearch = (event) => {
        setSearchData(event.target.value);
    }

    const handleSort = (field) => {
        setSortDirection(field === sortField && sortDirection === SortDirection.ascending
            ? SortDirection.descending
            : SortDirection.ascending);
        setSortField(field);
    }

    const getSortClass = (field) => {
        return field === sortField ? sortDirection : 'no-sort';
    }

    const EmptyList = () => {
        if (props.archive)
            return (
                <div className='d-flex flex-column align-items-center'>
                    <div className='list-empty-icon my-5'></div>
                    <div className='mb-4' style={{ fontSize: '24px' }}>У Вас нет архивных клиентов</div>
                </div>
            );

        return (
            <div className='d-flex flex-column align-items-center'>
                <div className='list-empty-icon my-5'></div>
                <div className='mb-4' style={{ fontSize: '24px' }}>У Вас нет активных клиентов</div>
                <div className='list-empty-desc mb-4'>
                    Как только Вы добавите клиентов или восстановите их из архива, Вы сможете увидеть детальную информацию о клиентах и их лицензиях
                </div>
                <button className='btn btn-primary mt-1' onClick={() => toggleModal()}>
                    <span className='plus-icon-white mr-2'></span>
                    Добавить клиента
                </button>
            </div>
        )
    }

    if (!clients) return;

    const ClientRow = ({ client }) => {
        return(
            <div className='table-row pointer d-flex flex-row align-items-start' key={client.id} onClick={() => info(client.id)}>
                <div id={"name" + client.id} className='w-20'>{client.name}</div><UncontrolledTooltip placement="top-start" target={"name" + client.id}>{client.name}</UncontrolledTooltip>
                <div className='w-15'>{client.inn || '—'}</div>
                <div className='w-15'>{client.kpp || '—'}</div>
                <div className='w-15'>
                    <span className={activationCountStyle(client)}>{client.activationsRemaining}</span>
                </div>
                <div className='w-15'>
                    <span className='activations-count font-md'>{client.activationsCount || 0}</span>
                </div>
                <div className='w-15'>{new Date(props.archive ? client.archivedDate : client.createdDate).toLocaleString("ru-RU").replace(',', '')}</div>
                <div className='w-5 d-flex justify-content-end align-items-center'>
                    <Popup
                        trigger={<span className='triple-dot-menu-icon px-1'></span>}
                        position="left center"
                        closeOnDocumentClick
                        className='click'
                    >
                        <div className='mb-2 pointer' onClick={() => info(client.id)}>Открыть</div>
                        <div className='pointer' onClick={() => handleArchiveClientClick(client)}>
                            {
                                props.archive ? 'Восстановить' : 'Переместить в архив'
                            }
                        </div>
                    </Popup>
                </div>
            </div>
        );
    }

    const TopItem = () => {
        if (props.archive)
            return(
                <div className='mb-2'>
                    <div className='pointer grey-1 d-flex flex-row align-items-center mb-2' 
                        onClick={() => changeArchiveFilter(false)}
                    >
                        <span className='nav-back-arrow'></span>
                        Назад
                    </div>
                    <h2>Архив клиентов</h2>
                </div>
            );

        return (
            <div className='d-flex flex-row align-items-center justify-content-between w-100 my-2'>
                <div className='d-flex flex-row align-items-center'>
                    <h2>Клиенты</h2>
                    <button className='btn btn-primary ml-5' onClick={() => toggleModal()}>
                        <span className='plus-icon-white mr-2'></span>
                        Добавить
                    </button>
                </div>
                <span 
                    className='pointer' 
                    style={{ color: 'rgba(79, 79, 79, 0.74)', fontSize: '24px' }}
                    onClick={() => changeArchiveFilter(true)}
                >
                    Архив
                </span>
            </div>
        )
    }

    const ClientsRows = ({ clients }) => {
        return clients.length
            ? clients.map(client => <ClientRow key={client.id} client={client} />)
            : <EmptyList />
    }

    return (
        <div>
            <TopItem />
            <div className="search-group mb-3 mt-4">
                <Popup
                    ref={filterPopupRef}
                    trigger={<button className={"btn search-filter " + (filterPopupIsOpen ? "search-filter-active" : "search-filter-inactive")} type="button">{filterField.label}</button>}
                    position="bottom"
                    closeOnDocumentClick
                    className='click'
                    onOpen={() => setFilterPopupIsOpen(true)}
                    onClose={() => setFilterPopupIsOpen(false)}
                >
                    <div className='mb-2 pointer' onClick={() => {handleFilter(null, 'Все')}}>Все</div>
                    <div className='mb-2 pointer' onClick={() => {handleFilter('name', 'Компания')}}>Компания</div>
                    <div className='mb-2 pointer' onClick={() => {handleFilter('inn', 'ИНН')}}>ИНН</div>
                    <div className='mb-2 pointer' onClick={() => {handleFilter('kpp', 'КПП')}}>КПП</div>
                </Popup>
                <input type="text" className="form-control search-input" placeholder="Поиск" onChange={handleSearch}></input>
            </div>
            
            <div className='table-header table-row d-flex flex-row'>
                <div className={'w-20 ' + getSortClass('name')} onClick={() => handleSort('name')}>Компания</div>
                <div className={'w-15 ' + getSortClass('inn')} onClick={() => handleSort('inn')}>Инн</div>
                <div className={'w-15 ' + getSortClass('kpp')} onClick={() => handleSort('kpp')}>Кпп</div>
                <div className={'w-15 ' + getSortClass('activationsRemaining')} onClick={() => handleSort('activationsRemaining')}>Осталось активаций</div>
                <div className={'w-15 ' + getSortClass('activationsCount')} onClick={() => handleSort('activationsCount')}>Активировано</div>
                {
                    props.archive
                        ? <div className={'w-15 ' + getSortClass('archivedDate')} onClick={() => handleSort('archivedDate')}>В архиве с</div>
                        : <div className={'w-15 ' + getSortClass('createdDate')} onClick={() => handleSort('createdDate')}>Создана</div>
                }
                <div className='w-5 d-flex'></div>
            </div>
            <ClientsRows clients={sortedAndFilteredClients} />
            <Modal isOpen={modal} toggle={toggleModal} size='sm'>
                <ModalBody>
                    <ClientAddEditModal close={toggleModal} onClientSaved={onClientSaved} />
                </ModalBody>
            </Modal>
            <ArchiveClientModal
                isOpen={isArchiveClientModalOpen}
                toggle={() => setIsArchiveClientModalOpen(!isArchiveClientModalOpen)}
                close={() => setIsArchiveClientModalOpen(false)}
                onExit={() => setClientToArchive(null)}
                client={clientToArchive}
                onSubmit={() => changeClientArchiveStatus(clientToArchive)}
            />
        </div>
    );
}
