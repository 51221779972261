import React, { useState } from 'react';
import Footer from '../../components/Footer';
import { Form, FormGroup, FormFeedback, Input } from 'reactstrap';
import axios from "axios";
import './Login.css';


export const Login = () => {
    const [client, setClient] = useState({
        login: '',
        password: ''
    });

    const [isLoading, setIsLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const handleChange = (event) => {
        setClient({...client, [event.target.name]: event.target.value});
    };

    const login = (event) => {
        event.preventDefault();
        setIsLoading(true);
        return axios.post("/api/user/login", client)
            .then((response) => {
                setIsLoading(false);
                setErrorMessage("");
                window.location.href = '/';
            })
            .catch(function (error) {
                setIsLoading(false);
                if (error.response) {
                    setErrorMessage(error.response.data.message);
                }
            });
    }

    console.log(errorMessage);

    return (
        <div class="d-flex align-items-center flex-column vh-100">
            <div className="login-wrapper d-flex flex-column justify-content-center flex-grow-1 w-100">
                <div class="logo login-logo mb-4 mx-auto"></div>
                <Form onSubmit={login}>
                    <FormGroup className="mb-4">
                        <Input type="login"
                            name="login"
                            id="login"
                            className="login-input"
                            required
                            invalid={errorMessage}
                            placeholder="Логин"
                            value={client.login}
                            onChange={handleChange}
                        />
                    </FormGroup>
                    <FormGroup className="mb-0">
                        <Input type="password"
                            name="password"
                            id="password"
                            className="login-input mb-4"
                            required
                            invalid={errorMessage}
                            placeholder="Пароль"
                            value={client.password}
                            onChange={handleChange}
                        />
                        <FormFeedback>
                            {errorMessage}
                        </FormFeedback>
                    </FormGroup>
                    <button type="submit" disabled={isLoading} className="btn btn-blue login-button d-flex align-items-center justify-content-center w-100">Войти {isLoading ? <span class="spinner"></span> : null}</button>
                </Form>
            </div>
            <Footer />
        </div>
    );
}
