import React, {useState, useEffect} from 'react';
import { useParams, useHistory} from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import axios from "axios";
import Popup from "reactjs-popup";

import {dateToLocale, activationCountStyle, renderLicenseStatus, LicenseStatus} from '../../utils/common';
import LicenseAddEditModal from '../licenses/LicenseAddEditModal';
import BlockLicenseModal from '../licenses/BlockLicenseModal';

const LicensesList = (props) => {
    const {id} = useParams();
    const history = useHistory();

    const [licenses, setLicenses] = useState([]);

    const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);

    const [blockLicenseModel, setBlockLicenseModel] = useState(false);
    const toggleBlockLicenseModal = (model) => setBlockLicenseModel(model);


    useEffect(() => {
        fetchLicenses(id);
    }, [id]);

    const fetchLicenses = (id) => {
        return axios.get(`/api/clients/licenses/${id}`)
            .then((response) => {
                setLicenses(response.data);
            });
    }

    const refresh = () => {
        props.refresh()
        fetchLicenses(id);
    }

    const afterBlocked = () => {
        refresh();
        toggleBlockLicenseModal();
    }

    const open = (license) => {
        history.push(`/license/${license.id}`);
    }

    const prevent = (e) => {
        e.stopPropagation();
    }

    const renderLicenseRow = (license) => {
        return(
            <div className='table-row pointer d-flex flex-row align-items-center' key={license.id} onClick={() => open(license)}>
                <div className='w-30'>{license.id}</div>
                <div className='w-15'>{dateToLocale(license.creationDate)}</div>
                <div className='w-20'>
                    <span className={activationCountStyle(license)}>{license.activationsLimit - license.activationsCount}</span>
                </div>
                <div className='w-10'>
                    <span className='activations-count font-md'>{license.activationsLimit}</span>
                </div>
                <div className='w-25 d-flex flex-row justify-content-between'>
                    {renderLicenseStatus(license.status)}
                    <div className='d-flex flex-row'>
                        <Popup
                            trigger={<a href={`/api/licenses/qr/${license.id}`} download={license.id} onClick={prevent}>
                                <i className='qr-code-icon'></i>
                            </a>}
                            position="bottom center"
                            on="hover"
                            className='hover'
                            >
                                Скачать
                        </Popup>
                        <Popup
                            trigger={<span className='triple-dot-menu-icon ml-4'></span>}
                            position="right center"
                            closeOnDocumentClick
                            className='click'
                        >
                            <div className='pointer' onClick={() => open(license)}>Открыть</div>
                            {
                                license.status === LicenseStatus.active
                                &&
                                <div className='pointer mt-2' onClick={() => toggleBlockLicenseModal(license)}>Отозвать </div>
                            }
                        </Popup>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className='d-flex flex-row my-4'>
                <h3 className='mr-4'>Лицензии</h3>
                <button className='btn btn-primary' onClick={() => toggleModal()}>
                    <span className='plus-icon-white mr-2'></span>
                    Добавить
                </button>
                <Modal isOpen={modal} toggle={toggleModal} size='sm'>
                    <ModalBody>
                        <LicenseAddEditModal close={toggleModal} refresh={refresh} clientId={id} />
                    </ModalBody>
                </Modal>
            </div>
            <div>
                <div className='table-header table-row d-flex flex-row'>
                    <div className='w-30'>Лицензия</div>
                    <div className='w-15'>Дата создания</div>
                    <div className='w-20'>Осталось активаций</div>
                    <div className='w-10'>Лимит</div>
                    <div className='w-25'>Статус</div>
                </div>
                {
                    licenses.map(l => renderLicenseRow(l))
                }
            </div>
            <Modal isOpen={!!blockLicenseModel}size='sm'>
                <ModalBody>
                    <BlockLicenseModal 
                        license={blockLicenseModel} 
                        onResolved={() => afterBlocked()} 
                        close={() => toggleBlockLicenseModal()} 
                    />
                </ModalBody>
            </Modal>
        </div>

    );
}

export default LicensesList;