import React, {useState} from 'react';
import {Form, FormGroup, Label, Input, FormFeedback} from 'reactstrap';
import axios from "axios";

const ClientAddEditModal = (props) => {
    const [client, setClient] = useState({
        name: props.client?.name || '',
        kpp: props.client?.kpp || '',
        inn: props.client?.inn || '',
        id: props.client?.id,
    });

    const [isValid, setValidation] = useState(true);
    const [errors, setErrors] = useState({});

    const checkInnError = (value) => {
        if(!value) 
            return 'Данное поле обязательно для заполнения';
        if(value.length !== 10 && value.length !== 12)
            return 'Длина ИНН должна составлять 10 или 12 символов';
        if(!/^\d+$/.test(value))
            return 'При указании ИНН допустимы только цифры';
    }

    const checkKppError = (value) => {
        if (value.length === 0)
            return; 
        if (value.length !== 9)
            return 'Длина КПП должна составлять 9 символов';
        if (!/^\d+$/.test(value))
            return 'При указании КПП допустимы только цифры';
    }

    const checkName = (value) => {
        if(!value || !value.trim())
            return 'Данное поле обязательно для заполнения';
    }

    const validators = {
        inn: checkInnError,
        kpp: checkKppError,
        name: checkName
    } 

    const handleChange = (event) => {
        const name = event.target.name;
        const model = {...client, [name]: event.target.value};
        setClient(model);
        validateField(name, event.target.value);
    };

    const validateField = (name, value) => {
        if(validators[name]){
            const desc = validators[name](value);
            let err = errors;
            if(desc){
                err[name] = desc;
            }
            else{
                delete err[name];
            }
            
            const isValid = Object.keys(err).length === 0;

            setErrors(err);
            setValidation(isValid);
        }
    }

    const isFormValid = (model) => {
        const err = Object.keys(validators).reduce((acc, key) => {
            let desc = validators[key](model[key]);
            if(desc){
                acc[key] = desc;
            }
            return acc;
        }, {});

        const isValid = Object.keys(err).length === 0;

        setErrors(err);
        setValidation(isValid);

        return isValid;
    }

    const addClient = (event) => {
        event.preventDefault();

        if(!isFormValid(client)){
            return;
        }

        return axios.post("/api/clients/post", client)
            .then((response) => {
                props.close();

                const newCLient = {
                    ...response.data,
                    activationsCount: 0,
                    activationsLimit: 0,
                    activationsRemaining: 0,
                };

                props.onClientSaved(newCLient);
            });
    }

    return (
        <div>
            <div className="title d-flex flex-row justify-content-between align-items-center mb-4">
                {props.client ? 'Редактирование клиента' : 'Новый клиент'}
                <span className="close-icon" onClick={props.close}></span>
            </div>
            <Form onSubmit={addClient}>
                <FormGroup className='mb-4'>
                    <Label for="name">Название компании</Label>
                    <Input
                        name="name"
                        id="name"
                        maxLength="256"
                        invalid={!!errors.name}
                        placeholder="Название компании"
                        value={client.name}
                        onChange={handleChange}
                    />
                    <FormFeedback>
                        {errors.name}
                    </FormFeedback>
                </FormGroup>
                <FormGroup className='mb-4'>
                    <Label for="inn">ИНН</Label>
                    <Input
                        name="inn"
                        maxLength="12"
                        id="inn"
                        placeholder="ИНН"
                        value={client.inn}
                        invalid={!!errors.inn}
                        onChange={handleChange}
                    />
                    <FormFeedback>
                        {errors.inn}
                    </FormFeedback>
                </FormGroup>
                <FormGroup className='mb-4'>
                    <Label for="kpp">КПП</Label>
                    <Input
                        name="kpp"
                        id="kpp"
                        maxLength="9"
                        placeholder="КПП"
                        value={client.kpp}
                        invalid={!!errors.kpp}
                        onChange={handleChange}
                    />
                    <FormFeedback>
                        {errors.kpp}
                    </FormFeedback>
                </FormGroup>

                <button type="submit" className='btn btn-blue w-100 mt-3' disabled={!isValid}>Сохранить</button>
            </Form>
        </div>

    );
}

export default ClientAddEditModal;