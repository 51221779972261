import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useHistory, useParams} from "react-router-dom";
import { Modal, ModalBody } from 'reactstrap';

import {activationCountStyle} from '../../utils/common';

import ClientAddEditModal from './ClientAddEditModal';
import ContactList from './ContactList';
import LicensesList from './LicensesList';


export const ClientInfo = () => {
    const {id} = useParams();
    const [client, setClient] = useState(null);

    const [clientModal, setClientModal] = useState(false);
    const toggleClientModal = () => setClientModal(!clientModal);

    const history = useHistory();

    useEffect(() => {
        fetchClient(id);
    }, [id]);

    const getBack = () => {
        if (client.isArchived)
            history.push('/archive');
        else
            history.push('/clients');
    }

    const fetchClient = (id) => {
        return axios.get(`/api/clients/${id}`)
            .then((response) => {
                setClient(response.data);
            });
    }

    const refresh = () => {
        fetchClient(id);
    }

    const onClientSaved = (updated) => {
        setClient({...client, ...updated});
    }

    if (!client) return null;

    return (
        <div >
            <div className='pointer grey-1 d-flex flex-row align-items-center mb-2' onClick={() => getBack()}>
                <span className='nav-back-arrow'></span>
                Назад
            </div>

            <div className='d-flex flex-row align-items-center mb-4'>
                <h2 className='m-0 mr-4'>{client.name}</h2>
                {
                    client.isArchived
                        ? <div className='status danger mr-4'>В архиве</div>
                        : <div className='status success mr-4'>Активный</div>
                }
                <div>
                    <span className='edit-icon' onClick={() => toggleClientModal()}></span>
                </div>
                <Modal isOpen={clientModal} toggle={toggleClientModal} size='sm'>
                    <ModalBody>
                        <ClientAddEditModal client={client} close={toggleClientModal} onClientSaved={onClientSaved} />
                    </ModalBody>
                </Modal>
            </div>
            <div className='font-lg'>
                <span className='mr-2'>ИНН </span>
                <span className='grey-1'>{client.inn || '—'}</span>

                <span className='ml-3 mr-2'>КПП </span>
                <span className='grey-1'>{client.kpp || '—'}</span>

                <div className='mt-2'>
                    <span className='mr-2'>Id</span>
                    <span className='grey-1'>{client.id}</span>
                </div>
                <div className='mt-2 d-flex flex-row'>
                    <span className='mr-2'>Осталось активаций</span>
                    <span className={activationCountStyle(client)}>
                        {client.activationsRemaining}
                    </span>
                    <span className='mx-2'>Всего активаций</span>
                    <span className='activations-count font-md'>
                        {client.activationsLimit || 0}
                    </span>
                </div>
            </div>
            <ContactList />
            <LicensesList refresh={refresh}/>
        </div>
    );
}
