import React from 'react';
import { Modal, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { dateTimeToLocale } from '../../utils/common';

const LicenseTransactionInfoModal = ({isOpen, toggle, close, transaction}) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} size='sm'>
            <ModalBody>
                <div className="title d-flex flex-row justify-content-between align-items-center mb-4">
                    <span>Количество активаций</span>
                    <span className="close-icon" onClick={close}></span>
                </div>
                <Form className="font-md">
                    <div className='mb-3'>
                        <span>Автор изменения: </span><span className='font-weight-bold'>{transaction?.author}</span>
                    </div>
                    <div className='mb-3'>
                        <span>Дата изменения: </span><span className='font-weight-bold'>{dateTimeToLocale(transaction?.operationDate)}</span>
                    </div>
                    <FormGroup>
                        <Label for="activationsCount">
                            Количество активаций
                        </Label>
                        <Input
                            id="activationsCount"
                            name="activationsCount"
                            value={(transaction?.activationsCount > 0 ? '+' : '') + transaction?.activationsCount}
                            type="text"
                            readOnly
                            bsSize="sm"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="comment">
                            Комментарий
                        </Label>
                        <Input
                            id="comment"
                            name="comment"
                            value={transaction?.comment}
                            type="textarea"
                            readOnly
                            maxLength={200}
                            rows={8}
                            bsSize="sm"
                            style={{resize: "none"}}
                        />
                    </FormGroup>
                </Form>
            </ModalBody>
        </Modal>
    );
}

export default LicenseTransactionInfoModal;
