import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { Navbar, Container, NavLink, Modal, ModalBody } from 'reactstrap';
import ConfirmModal from '../shared/ConfirmModal';

import './NavMenu.css';

const NavMenu = () => {
    const [user, setUser] = useState(null);

    const [logoutConfirmModal, setLogoutConfirmModal] = useState(false);
    const toggleLogoutConfirmModal = () => setLogoutConfirmModal(!logoutConfirmModal);

    useEffect(() => {
        fetchUser();
    }, []);

    const fetchUser = () => { 
        return axios.get(`/api/user/current`)
            .then((response) => {
                setUser(response.data)
            });
    }

    const logout = (event) => {
        event.preventDefault();
        return axios.post("/api/user/logout")
            .then((response) => {
                window.location.href = '/';
            })
    }

    return (
        <header>
            <Navbar className="navbar border-bottom mb-4" light>
                <Container>
                    <div className='d-flex flex-row align-items-center'>
                        <Link to="/clients/" className='mr-4'>
                            <div className='logo'></div>
                        </Link>
                        
                        <NavLink tag={Link} to="/clients/">Клиенты</NavLink>
                    </div>
                    <div className="d-inherit" >
                        {user?.name}
                        <span className="logout-icon" onClick={() => toggleLogoutConfirmModal()}></span>
                    </div>
                </Container>
            </Navbar>
            <Modal isOpen={!!logoutConfirmModal} toggle={toggleLogoutConfirmModal} size='sm'>
                <ModalBody>
                    <ConfirmModal
                        title="Выход из аккаунта"
                        dectiption="Вы уверены, что хотите выйти?"
                        btnText="Выйти"
                        onConfirmed={logout}
                        close={toggleLogoutConfirmModal}
                    />
                </ModalBody>
            </Modal>
        </header>
    );
}

export default NavMenu;
