import React, {useState} from 'react';
import axios from 'axios';
import { Button } from 'reactstrap';
import {useHistory} from "react-router-dom";



const RegenerateLicenseModal = (props) => {
    const history = useHistory();

    const [newLicense, setNewLicense] = useState(null);
    const [loading, setLoading] = useState(false);

    const regenerate = () => {
        setLoading(true);
        return axios.post(`/api/licenses/regenerate`, props.license)
            .then((response) => {
                setNewLicense(response.data);
                props.onResolved();
            })
            .finally(() => setLoading(false));
    }

    const open = () => {
        props.close();
        history.push(`/license/${newLicense.id}`);
    } 

    return (
        <div>
            <div className="title d-flex flex-row justify-content-between align-items-center mb-4">
                Перегенерировать QR-код
                <span className="close-icon" onClick={props.close}></span>
            </div>
            <div className='title mb-4'>
                {
                    newLicense
                    ? 'Новая лицензия успешно сгенерирована.'
                    : 'Вы уверены, что хотите перегенерировать QR-код? Это действие нельзя отменить.'
                }
            </div>
            <div className='d-flex flex-row justify-content-between w-100'>
                <Button
                    color="danger"
                    outline
                    onClick={props.close}
                >
                    Отмена
                </Button>
                {
                    !newLicense &&
                    <Button
                        color="warning"
                        outline
                        disabled={loading}
                        onClick={regenerate}
                    >
                        Перегенерировать
                    </Button>
                }
                {
                    newLicense &&
                    <button className='btn btn-primary mr-3' onClick={open}>
                        Открыть
                    </button>
                }
            </div>
        </div>

    );
}

export default RegenerateLicenseModal;