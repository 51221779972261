import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

const ArchiveClientModal = ({isOpen, toggle, close, onExit, client, onSubmit}) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} onExit={onExit} size='sm'>
            <ModalBody>
                <div>
                    <div className="title d-flex flex-row justify-content-between align-items-center mb-4">
                        Перемещение в архив
                        <span className="close-icon" onClick={close}>
                        </span>
                    </div>
                    <div className='title mb-4'>
                        У компании{client && <strong> {client.name}</strong>}, которую вы планируете перенести в архив, есть активные лицензии. Все они будут отозваны.
                    </div>
                    <div className='title mb-4'>
                        Вы точно хотите это сделать?
                    </div>
                    <div className='d-flex flex-row justify-content-between w-100'>
                        <Button
                            color="danger"
                            outline
                            onClick={close}
                        >
                            Отмена
                        </Button>
                        <Button
                            color="warning"
                            outline
                            onClick={onSubmit}
                        >
                            Подтвердить
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ArchiveClientModal;
